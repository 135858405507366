<template>
  <div class="text-center border">
    <b-row>
      <b-col>
      </b-col>
      <b-col>
        <b-spinner></b-spinner>
      </b-col>
      <b-col>
      </b-col>

    </b-row>

    <br>
    <p>Loading</p>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>