<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <h1>{{post.title}}</h1>
        <div v-if="post.rawHTML !== ''" v-html="post.rawHTML"></div>
        <div v-for="(element, idx) in post.elements" :key="idx">
          <pre v-if="element.isCode" v-highlightjs><code class="go" >{{element.text}}</code></pre>
          <div v-else v-html="element.text"></div>
        </div>
        <hr>
      </div>
      <div class="row">
        <div class="col">
          <b-card
              title="Book"
              :img-src="require('@/assets/images/image-book/practical-go-lessons-book.jpg')"
              img-top
              tag="article"
              style="max-width: 20rem;"
              class="mb-2"
          >
            <b-card-text>
              Read the "Practical Go lessons" book
            </b-card-text>
            <router-link :to="{name:this.routeNames.HOME}">
              <b-button variant="success">Go</b-button>
            </router-link>
          </b-card>
        </div>
        <div class="col">
          <b-card
              title="Video Course"
              img-src="https://cdn.practical-go-lessons.com/tuto_image_1.jpg"
              img-top
              tag="article"
              style="max-width: 20rem;"
              class="mb-2"
          >
            <b-card-text>
              Check the Video Course
            </b-card-text>
            <router-link :to="{ name: routeNames.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}">
              <b-button variant="success">Go</b-button>
            </router-link>
          </b-card>
        </div>
      </div>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>

import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import Consts from "@/consts";
import WebService from "@/webservice";
import Loader from "@/components/Loader";
const hljs = require('highlight.js/lib/core');  // require only the core library
// separately require languages
hljs.registerLanguage('go', require('highlight.js/lib/languages/go'));

export default {
  name: "PostDetail",
  components: {Loader, Footer,NavBar},
  props: {
    postId: String,
    slug: String,
  },
  data() {
    return {
      titleMeta : "",
      descriptionMeta : "",
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
      loading: false,
      routeNames: Consts.ROUTE_NAMES,
      post: null,
    }
  },
  metaInfo () {
    return {
      title: this.titleMeta,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.titleMeta },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.titleMeta},
        { property: 'og:description', content: this.descriptionMeta },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  created() {
    window.scrollTo(0,0);
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      WebService.getPostById(this.postId).then((res)=>{
        this.post = res.data
        this.titleMeta = this.post.title+"| Practical Go Lessons"
        this.descriptionMeta = this.post.shortDescription
        this.loading = false
        this.highlightCode()
      }).catch((err)=> {
        console.error(err)
        this.loading = false
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    highlightCode(){
      this.$nextTick(() => {
        document.querySelectorAll('code').forEach((el) => {
          console.log(el)
          hljs.highlightElement(el);
        });
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>